import React, { useState } from 'react';
import { Radio } from 'antd';
const ElpsImages = (props) => {
  const [value, setValue] = useState(null);
  const onChange = (e) => {
    setValue(e.target.value);
    props.radioHandle(e.target.value);
  };
  return (
    <div className="amount_image">
      <p>WASTE TYPE</p>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1}>SOLID</Radio>
        <Radio value={2}>LIQUID</Radio>
      </Radio.Group>
    </div>
  );
};
export default ElpsImages;
